<template>
  <scroll-container>
    <PrivacyPolicy />
  </scroll-container>
</template>

<script>
import PrivacyPolicy from '../legal/PrivacyPolicy.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    PrivacyPolicy
  }
}
</script>
